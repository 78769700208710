<template>
  <div>
    <v-list-item @click="$emit('select_chat', chat)">

      <template v-if="chat.name.indexOf('private') !== -1">
        <v-list-item-avatar v-if="chat.participants[0].id != $store.state.user.currentUser.id">
          <v-img v-if="chat.participants[0]" :src="chat.participants[0].avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-avatar v-else>
          <v-img v-if="chat.participants[1]" :src="chat.participants[1].avatar"></v-img>
        </v-list-item-avatar>
      </template>


      <v-list-item-avatar v-else>
        <v-img
            :src="'https://ui-avatars.com/api/?name='+chat.name+'&rounded=true&background=1967c3&color=ffffff'"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>

        <v-list-item-title>
          <div class="d-flex flex-nowrap  ">
            <div style="overflow: hidden">
              {{ chat.goodName }}
            </div>
            <v-spacer/>
            <v-badge class="pl-2" color="red" v-if="!chat.is_reading" dot inline/>
          </div>

        </v-list-item-title>
        <v-list-item-subtitle v-if="chat.messagesCount > 0">
          {{ chat.lastMessage.text }}
        </v-list-item-subtitle>
      </v-list-item-content>

    </v-list-item>
    <v-divider/>
  </div>
</template>

<script>
export default {
  name: "ChatItemComponent",
  props: ['chat'],
  mounted() {
    if(window.Echo) {
      window.Echo.private(`messages.chat.${this.chat.id}`)
          .listen('chat_changed', (data) => {
            this.chat = data.chat;
            this.$emit('chatUpdated')
            this.$store.dispatch('getChats')
          });
    }
  },
  beforeDestroy() {
    if (this.chat && window.Echo) window.Echo.leave(`messages.chat.${this.chat.id}`)
  }
}
</script>

<style scoped>

</style>
