import endpoint from "@/api/endpoint";

export default {

    store(data, progress = () => {
    }) {
        return window.axios.post(endpoint + '/api/files', data, {
            onUploadProgress: progress
        });
    },


}
