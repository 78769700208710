import endpoint from "@/api/endpoint";

export default {

    create(db_name){
        return window.axios.post(endpoint+'/api/hosting/createDatabase', {db_name})
    },

    destroy(id){
        return window.axios.post(endpoint+'/api/hosting/'+id+'/destroyDatabase')
    },
    get(project_id){
        return window.axios.get(endpoint+'/api/project/'+project_id+'/hosting');
    },

    all(){
        return window.axios.get(endpoint+'/api/hosting');
    },
}
