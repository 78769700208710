import posts from "@/api/posts";

export default {
    state: {
        posts: [],
        wasLoaded: false
    },
    mutations: {
        setFeed(state, posts) {
            state.posts = posts;
            state.wasLoaded = true;
        },
        setWasLoaded(state, value) {
            state.wasLoaded = value;
        },
    },
    actions: {
        getFeed({commit}) {
            commit('setWasLoaded', false);
            return posts.getFeed().then((response) => {
                commit('setFeed', response.data);
            })
        },
    }
}


