<template>
  <div class="input-container">
    <label>
      <b v-if="label" style="padding: 5px;">{{label || placeholder}}</b>
      <input :inputmode="inputmode" :min="min" :max="max" :readonly="readOnly" :autofocus="autofocus"
             v-on:keyup.enter="focusNext($event)"
             ref="direct_input"
             :autocomplete="suggestFunc ? 'new-password' : 'on'" @blur="onBlur" @focus="onFocus"
             :class="{
                'input_has-error': errors.length > 0,
                'input_readonly': readOnly,
                'input_is-valid': isValid !== undefined && isValid === true,
                }"
             :name="name"
             :placeholder="placeholder" :type="type" :value="value" class="input"
             @input="processInput($event.target.value);"
      />
    </label>
    <InputSelectable v-if="displaySuggest && suggests.length > 0"
                     @select="processInput($event); focusNext($refs.direct_input); displaySuggest = false;"
                     :items="suggests"/>
    <div v-if="errors.length > 0" class="input__error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import InputSelectable from "@/components/UI/InputSelectable.vue";

export default {
  name: "InputUI",
  components: {InputSelectable},
  props: {
    isValid: {
      default: undefined,
      type: Boolean
    },
    value: {},
    min: {},
    max: {},
    label: {},
    autofocus: {
      default: false,
      type: Boolean
    },
    placeholder: {},
    type: {},
    name: String,
    inputmode: String,
    readOnly: Boolean,
    errors: {
      type: Array,
      default: () => []
    },
    suggestFunc: {},
  },
  data() {
    return {
      suggests: [],
      displaySuggest: false
    }
  },
  methods: {
    focusNext(event) {
      let elem = event.target;
      const currentIndex = Array.from(elem.form.elements).indexOf(elem);
      let el = elem.form.elements.item(currentIndex + 1);
      if (el) {
        el.focus();
        event.stopPropagation();
        event.preventDefault();
      } else {
        this.$emit('submit')
      }
    },
    onFocus() {
      if (this.suggestFunc) {
        this.displaySuggest = true;
      }
    },
    onBlur() {
      setTimeout(() => this.displaySuggest = false, 500);
    },
    suggestInput: _.throttle((value, vue) => {
      if (vue.suggestFunc) {
        vue.suggestFunc(value).then((items) => {
          vue.suggests = items;
          // eslint-disable-next-line no-console
          console.log(items);
        });
      }
    }, 1000),
    processInput(value) {
      this.suggestInput(value, this);
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
.input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  background-color: #fefefe;
}


.input:focus {
  border: 1px solid #dedede;
  outline: none;
}

.input_has-error, .input_has-error:focus {
  border: 1px solid #f6acac !important;
}

.input__error {
  color: #e78282 !important;
  font-size: 14px;
  top: -15px;
  left: 10px;
  padding: 0 5px;
}

.input_is-valid {
  border: 1px solid #92d295;
}


.input-container {
  position: relative;
  margin-bottom: 0.5em;
}

.input_readonly {
  background-color: #efefef;
}
</style>
