<template>
  <div style="width: 100%; display: flex; height: 100%; flex-direction: column;">
    <v-dialog v-if="!loading && chat"
              v-model="participantDialog"
              width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="text-center">
          <a v-bind="attrs" v-on="on"
             style="font-size: 12px; color: #3c8fb7"
          >
            Показать участников ({{ chat.participantsCount }})
          </a>
        </div>
      </template>
      <v-card>
        <v-card-title>
          Участники чата
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item :href="'/#/profile/'+p.id" v-for="p in chat.participants" :key="p.id">
              <v-list-item-avatar>
                <v-img
                    :src="'https://ui-avatars.com/api/?name='+p.name+'&rounded=true&background=1967c3&color=ffffff'"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ p.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="p.id === chat.owner_id">Организатор</v-list-item-subtitle>
                <v-list-item-subtitle v-else>Участник</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

      </v-card>
    </v-dialog>
    <v-row v-if="loading" :style="`width: 100%; height: 100vh;`" align="center" justify="center">
      <v-progress-circular class="mt-5" color="primary" indeterminate width="2"/>
    </v-row>
    <div v-else-if="messages.data.length > 0" id="scroll-target"
         ref="scroll"
         :style="`background: white; height: 100%;  height: 100vh;`"
         class="overflow-y-auto overflow-x-hidden px-2"
    >
      <SmartServiceMessage class="mb-3">{{
          $moment.utc(messages.data[0].created_at).local().format("DD.MM")
        }}
      </SmartServiceMessage>
      <div v-for="(message, index) in messages.data" :key="index">
        <SmartServiceMessage
            v-if="index > 0 && $moment.utc(messages.data[index].created_at).diff(messages.data[index - 1].created_at, 'days') > 0"
            class="mb-3">
          {{ $moment.utc(messages.data[index].created_at).local().format("DD.MM") }}
        </SmartServiceMessage>
        <SmartMessage :message="messages.data[index]" class="mb-4"/>
      </div>

    </div>
    <div v-else class="mt-3 text-center" :style="`height: ${height}px `">
      Сообщений пока нет
    </div>
    <FileHorizontalViewer :files="files"/>
    <div ref="fileform" class="messenger_input_wrap" draggable="true">
        <v-textarea counter maxlength="800"  full-width flat  v-model="messageToSend" class="messenger_input" placeholder="Ваше сообщение"
                  rows="1" auto-grow type="text"></v-textarea>
      <div  style="position: absolute; right: 10px; top: 0;">
        <v-btn :loading="loading" :disabled="messageToSend.length < 1 && files.length < 1" color="primary" @click="sendMessage"
               icon>
          <v-icon>mdi-send</v-icon>
        </v-btn> <br/>
        <input multiple @input="onFilesInput" type="file" ref="input_file" style="display: none">

        <FileUploadV2 v-model="files">
          <template v-slot:activator="props">
            <v-btn icon @click="props.attachFiles">
              <v-icon>mdi-attachment</v-icon>
            </v-btn>
          </template>
        </FileUploadV2>

      </div>

    </div>

  </div>
</template>

<script>
import chats from "@/api/chats.js";
import SmartMessage from "@/components/Messages/SmartMessage.vue";
import SmartServiceMessage from "@/components/Messages/SmartServiceMessage.vue";
import analytics from "@/api/analytics.js";
import files from "@/api/files.js";
import FileHorizontalViewer from "@/components/Feed/FileHorizontalViewer";
import FileUploadV2 from "@/components/Feed/FileUploadV2";


export default {
  name: "SmartChatComponent",
  components: {FileHorizontalViewer, SmartMessage, SmartServiceMessage, FileUploadV2},
  props: ['chat_id', 'height'],
  data() {
    return {
      chat: {},
      loading: true,
      participantDialog: false,
      offsetTop: 0,
      messages: [],
      messageToSend: "",
      files: [],
      messageInputHeight: 'auto'
    }
  },
  computed: {
  },
  watch: {
    chat_id(){
      this.unload();
      this.load();
    },
    messageToSend(){
      if(!this.messageToSend.length){
        this.messageInputHeight = '200px';
      }
      this.messageInputHeight = this.$refs.messageInput ? this.$refs.messageInput.scrollHeight + 'px' : '100px';
    }
  },
  methods: {
    attachButton(){
      this.$refs.input_file.click();
    },
    onFilesInput(){
      this.$refs.input_file.files.forEach((f) => {
        this.uploadFile(f);
      });
    },
    sendMessage() {
      if (this.messageToSend.length < 1 && this.files.length < 1) return false;
      if(this.messageToSend.length < 1){
        this.messageToSend = 'Файлы'
      }
      this.messageToSend = this.messageToSend.trim()
      let message = {
        id: -1,
        owner: this.$store.state.user.currentUser,
        readers: [this.$store.state.user.currentUser],
        text: this.messageToSend,
        created_at: this.$moment.utc().toISOString()
      };
      analytics.event({event: 'CHAT_SEND_MESSAGE', details: message});
      this.messages.data.push(message)
      setTimeout(() => {
        this.scrollToEnd();
      }, 1);
      chats.sendMessage({id: this.chat_id, text: this.messageToSend, files: this.files.map((f) => f.id)}).then((r) => {
        r.data.readers = [this.$store.state.user.currentUser]
        this.addNewMessage(r.data);
      });
      this.messageToSend = "";
      this.files = [];
    },

    onScroll(e) {
      this.offsetTop = e.target.scrollTop
    },

    scrollToEnd() {
      // eslint-disable-next-line no-console
      console.log(this.$refs.scroll);
      this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight;
    },

    uploadFile(file) {
      let bodyFormData = new FormData();
      bodyFormData.append('file', file);
      files.store(bodyFormData).then((r) => {
        // eslint-disable-next-line no-console
        console.log(r.data);
        this.files = [...this.files, r.data];
      })
    },

    addNewMessage(message) {
      if (parseInt(message.owner_id) !== parseInt(this.$store.state.user.currentUser.id)) {
        this.messages.data.push(message);
        setTimeout(() => {
          this.scrollToEnd();
        }, 1);
      }
      if (this.messages.data && this.messages.data.length > 0) {
        this.messages.data = this.messages.data.map((el) => {
          if (el.id === -1) return message;
          else if (message.id === el.id) return message;
          return el;
        })
      }
    },

    load(){
      this.$refs.fileform.addEventListener('dragover', function () {
        this.$refs.fileform.classList.add('drag-active');
      }.bind(this));

      this.$refs.fileform.addEventListener('dragleave', function () {
        this.$refs.fileform.classList.remove('drag-active');
      }.bind(this));

      this.$refs.fileform.addEventListener('drop', function (e) {
        this.$refs.fileform.classList.remove('drag-active');
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          // this.uploadFile(e.dataTransfer.files[i]);
          // eslint-disable-next-line no-console
          console.log(e.dataTransfer.files[i]);
          this.uploadFile(e.dataTransfer.files[i]);

        }
        e.preventDefault();
        e.stopPropagation();

      }.bind(this));

      chats.getChatById({id: this.chat_id}).then((r) => {
        this.chat = r.data;
        this.$emit('chatNameUpdate', r.data.goodName)
      }).catch(() => {
        this.loading = false;
      })

      chats.messages_p({id: this.chat_id}).then((r) => {
        this.loading = false;
        this.messages = r.data;
        this.messages.data = this.messages.data.reverse()

        if (this.messages.data.length > 0) {
          setTimeout(() => {
            this.scrollToEnd();
          }, 1);
        }

        if(window.Echo) {
          window.Echo.private(`messages.chat.${this.chat_id}`)
              .listen('messages_send', (message) => {
                message.readers = [this.$store.state.user.currentUser]
                this.addNewMessage(message);
              });
        }
      })
    },
    unload(){
      if (this.chat && window.Echo) window.Echo.leave(`messages.chat.${this.chat_id}`)
    }
  },


  mounted() {
    this.load();

  },
  beforeDestroy() {
    this.unload();
  }
}
</script>

<style scoped>
.messenger_input_wrap {
  width: 100%;
  bottom: 0;
  padding: 10px 20px;
  background: white;
  border-top: 1px solid #eaeaea;
  position: relative;
}

.messenger_input_wrap textarea {
  width: 100%;
  height: 100%;
  resize: none;

}
.messenger_input {
  padding-right: 25px;
  transition: height 1s;
}


.messenger_input:focus {
  outline: none;
}


.container::-webkit-scrollbar {
  width: 0;
}

.drag-active {
  border: 2px dashed #3586ff;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  position: relative;
}

.drag-active:after {
  content: "Перетащите файлы сюда";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.files {
  overflow-x: scroll;
  width: 100%;
  display: flex;
  height: 150px;
}

.files__file {
  background: #eaeaea;
  padding: 10px;
  border-radius: 10px;
  font-size: 10px;
  margin: 5px 10px;
}
</style>
