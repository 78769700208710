import endpoint from "@/api/endpoint";

export default {
    makePost(data){
        return window.axios.post(endpoint+'/api/post', data);
    },

    delete(data){
        return window.axios.post(endpoint+'/api/post/'+data.id+'/delete', data);
    },

    getFeed(){
        return window.axios.get(endpoint+'/api/feed');
    },

    like(data){
        return window.axios.post(endpoint+'/api/post/'+data.id+'/like', data);
    },

    getComments(data){
        return window.axios.get(endpoint+'/api/post/'+data.id+'/comments', data);
    },

    addComment(data){
        return window.axios.post(endpoint+'/api/post/'+data.id+'/comments', data);
    },

}
