import task_statuses from "@/api/task_statuses.js";
import tasks from "@/api/tasks.js";

export default {
    state: {
        tasks: [],
        task_statuses: []

    },
    mutations: {
        setTaskStatuses(state, task_statuses) {
            state.task_statuses = task_statuses;
        },

        setTasks(state, tasks) {
            state.tasks = tasks;
        },
        updateTask(state, task) {
            state.tasks = [...state.tasks.map((el) => (parseInt(el.id) === parseInt(task.id)) ? task : el)]
        },

        addTask(state, task) {
            state.tasks = [...state.tasks, task]
        },


    },
    getters: {
        getTasksByStatusID(state) {
            return (status_id) => {
                return state.tasks.filter((el) => parseInt(el.task_statuses_id) === parseInt(status_id))
            }
        },

        getTaskByID(state) {
            return (id) => {
                return state.tasks.filter((el) => parseInt(el.id) === parseInt(id))[0]
            }
        }
    },
    actions: {
        getTaskStatuses({commit}) {
            return task_statuses.all().then((response) => {
                commit('setTaskStatuses', response.data);
            })
        },
        updateTask({commit}, data) {
            return tasks.update(data).then((r) => {
                return commit('updateTask', r.data);
            })
        },

        syncTask({commit}, data) {
            return tasks.item(data).then((r) => {
                return commit('updateTask', r.data);
            })
        },

        storeTask({commit}, data) {
            return tasks.store(data).then((r) => {
                return commit('addTask', r.data);
            })
        },
    }
}


