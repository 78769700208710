import project_types from "@/api/project_types";
import projects from "@/api/projects";
import project_roles from "@/api/project_roles";
import project_files from "@/api/project_files";
import chats from "@/api/chats.js";


export default {
    state: {
        project_types: [],
        projects: {data: []},
        archiveReasons: [],
        userProjects: [],
        currentProject: null,
        currentProjectParticipants: [],
        currentUserStatusInProject: null,
        currentProjectFiles: [],
        currentProjectChat: null,
        currentProjectTasks: null,
        currentProjectPosts: [],
        currentProjectExams: [],

    },
    mutations: {

        setExams(state, exams) {
            state.currentProjectExams = exams
        },

        setPosts(state, posts) {
            state.currentProjectPosts = posts
        },

        setArchiveReasons(state, reasons) {
            state.archiveReasons = reasons
        },

        setCurrentProjectChat(state, chat) {
            state.currentProjectChat = chat
        },
        setCurrentProjectFiles(state, files) {
            state.currentProjectFiles = files;
        },

        setCurrentProjectTasks(state, tasks) {
            state.currentProjectTasks = tasks;
        },

        setCurrentUserStatusInProject(state, status) {
            state.currentUserStatusInProject = status;
        },
        setProjectTypes(state, project_types) {
            state.project_types = project_types
        },

        updateParticipant(state, participant) {
            state.currentProjectParticipants = state.currentProjectParticipants.map((el) => {
                if (el.id == participant.id) return participant
                else return el
            });
            return true;
        },

        removeParticipant(state, participant) {
            state.currentProjectParticipants = state.currentProjectParticipants.filter((el) => {
                return el.id != participant.id
            });
            return true;
        },

        addProject(state, project) {
            state.projects.data.push(project)
            return project;
        },

        setProjects(state, projects) {
            state.projects = projects;
        },

        setProjectsLazy(state, projects) {
            projects.data = [...state.projects.data, ...projects.data]
            state.projects = projects
        },

        setUserProjects(state, projects) {
            state.userProjects = projects;
        },

        removeProject(state, project) {
            state.projects.data = state.projects.data.filter((el) => {
                return parseInt(el.id) !== parseInt(project.id)
            })
        },

        updateProject(state, project) {
            // eslint-disable-next-line no-console
            console.log(project);
            state.projects.data = [...state.projects.data.map((el) => {
                return parseInt(el.id) === parseInt(project.id) ? project : el
            })];
            if (parseInt(state.currentProject.id) === parseInt(project.id)) state.currentProject = project;
        },

        setCurrentProject(state, project) {
            state.currentProject = project;
        },
        setCurrentProjectParticipants(state, participants) {
            state.currentProjectParticipants = participants;
        },

    },
    getters: {
        canEditProject(state) {
            return state.currentProject.permissions.can_edit_info;
        },

        isProjectAdmin() {
            return false;
        },

        isAuth(_, __, state) {
            return state.user.currentUser.id > 0;
        },

        isProjectParticipant(state) {
            return state.currentUserStatusInProject != null
        },

        canWriteToChat(state, getters) {
            return getters.isProjectAdmin || getters.isProjectParticipant;
        },


        getParticipantsByProjectRole: (state) => (project_role_id) => {
            return state.currentProjectParticipants.filter((p) => {
                return parseInt(p.pivot.project_role_id) === parseInt(project_role_id)
            })
        }
    },
    actions: {
        getProjectTypes({commit}) {
            return project_types.all().then((response) => {
                commit('setProjectTypes', response.data);
            })
        },
        getCurrentProjectTasks({commit}, data) {
            return projects.tasks(data).then((response) => {
                commit('setCurrentProjectTasks', response.data);
            })
        },

        getArchiveReasons({commit}) {
            return projects.getArchiveReasons().then((response) => {
                commit('setArchiveReasons', response.data);
            })
        },

        getProjectPosts({commit}, data) {
            return projects.posts(data).then((response) => {
                commit('setPosts', response.data);
            })
        },

        getProjectExams({commit, state}) {
            return projects.exams({id: state.currentProject.id}).then((response) => {
                commit('setExams', response.data);
            })
        },

        getProjectChat({commit, state}) {
            return chats.getChatById({id: state.currentProject.chat_id}).then((response) => {
                commit('setCurrentProjectChat', response.data);
            })
        },

        getProjectFiles({commit}, {project_id}) {
            return project_files.all(project_id).then((response) => {
                commit('setCurrentProjectFiles', response.data);
            })
        },

        removeProjectFile({dispatch, state}, {project_file_id}) {
            return new Promise(((resolve, reject) => {
                project_files.remove(project_file_id).then(() => {
                    dispatch('getProjectFiles', {project_id: state.currentProject.id}).then(() => {
                        resolve();
                    });
                }).catch((e) => {
                    reject(e);
                })
            }))
        },


        storeProjectFile({dispatch, state}, {project_id, data}) {
            return new Promise(((resolve, reject) => {
                project_files.store(project_id, data).then(() => {
                    dispatch('getProjectFiles', {project_id: state.currentProject.id}).then(() => {
                        resolve();
                    });

                }).catch((e) => {
                    reject(e);
                })
            }))
        },

        getProjects({commit, getters}, data) {
            if (getters.isAuth) {
                return projects.auth_all(data).then((response) => {
                    commit('setProjects', response.data);
                    return true;
                })
            } else {
                return projects.all(data).then((response) => {
                    commit('setProjects', response.data);
                    return true;
                })
            }

        },
        getLazyProjects({commit, getters}, data) {
            if (getters.isAuth) {
                return projects.auth_all(data).then((response) => {
                    commit('setProjectsLazy', response.data);
                    return true;
                })
            } else {
                return projects.all(data).then((response) => {
                    commit('setProjectsLazy', response.data);
                    return true;
                })
            }

        },
        getProjectsByUser({commit}, data) {
            return projects.byUserID(data.user_id).then((response) => {
                commit('setUserProjects', response.data);
                return true;
            })
        },

        getProjectParticipants({commit}, data) {
            return projects.participants(data).then((r) => {
                commit('setCurrentProjectParticipants', r.data)
                return true
            })
        },

        removeProject({commit}, data) {
            return projects.remove(data).then(() => {
                commit('removeProject', data);
                return true;
            })
        },

        archiveProject({commit}, data) {
            return projects.archiveProject(data).then((response) => {
                commit('updateProject', response.data);
                return true;
            })
        },

        approveProject({commit}, data) {
            return projects.approve(data).then((r) => {
                commit('updateProject', r.data);
                return true;
            })
        },

        declineProject({commit}, data) {
            return projects.decline(data).then((r) => {
                commit('updateProject', r.data);
                return true;
            })
        },

        toModeration({commit}, data) {
            return projects.toModeration(data).then((r) => {
                commit('updateProject', r.data);
                return true;
            })
        },

        storeProject({commit}, data) {
            return new Promise((resolve, reject) => {
                projects.store(data).then((r) => {
                    resolve(r);
                    return commit('addProject', r.data)
                }).catch((e) => {
                    reject(e);
                })
            });

        },

        updateProject({commit}, data) {
            return projects.update(data).then((r) => {
                return commit('updateProject', r.data)
            });

        },

        updateProjectRole({dispatch, state}, data) {
            return project_roles.update(data).then(() => {
                return dispatch('getCurrentProject', {id: state.currentProject.id})
            });

        },



        storeProjectRole({dispatch, state}, data) {
            return new Promise((resolve, reject) => {
                project_roles.store(data).then((response) => {
                    resolve(response);
                    dispatch('getCurrentProject', {id: state.currentProject.id})
                }).catch((e) => {
                    reject(e)
                })
            });
        },


        removeProjectRole({dispatch, state}, data) {
            return new Promise((resolve, reject) => {
                project_roles.delete(data).then((response) => {
                    resolve(response);
                    dispatch('getCurrentProject', {id: state.currentProject.id})
                }).catch((e) => {
                    reject(e)
                })
            });
        },

        getCurrentProject({commit}, data) {
            return projects.item(data).then((r) => {
                commit('setCurrentProject', r.data);
                return true
            })
        },

        approveUserOnProject({commit, dispatch}, data) {
            return projects.approveParticipant(data).then((r) => {
                commit('updateParticipant', r.data)
                dispatch('getCurrentUserStatusInProject', data)
            });
        },

        declineUserOnProject({commit, dispatch}, data) {
            return projects.declineParticipant(data).then((r) => {
                commit('removeParticipant', r.data);
                dispatch('getCurrentUserStatusInProject', data)
                dispatch('getCurrentProject', data)
            });
        },

        getCurrentUserStatusInProject({commit}, data) {
            return projects.status(data).then((r) => {
                commit('setCurrentUserStatusInProject', r.data.pivot);
            })
        },

        enterToProject({commit, dispatch}, data) {
            return projects.enterToProject(data).then((r) => {
                commit('setCurrentUserStatusInProject', r.data.pivot);
                dispatch('getProjectParticipants', data)
                return true
            })
        },

        includeToProject({dispatch}, data) {
            return projects.includeToProject(data).then(() => {
                dispatch('getProjectParticipants', data)
                return true
            })
        },
        changeParticipantPermission({commit}, data) {
            return projects.changeParticipantPermission(data).then((r) => {
                commit('updateParticipant', r.data)
            })
        }
    }
}


