import university from "@/api/university.js";

export default {
    state: {
        university: {
            name: 'Loading...',
            color: '#1976d2'
        }
    },
    mutations: {
        setUniversity(state, uni) {
            state.university = uni
        },

    }
    ,
    actions: {
        getUniversity({commit}, {hostname}) {
            return university.info(hostname).then((r) => {
                commit('setUniversity', r.data);
            })
        }
    }
}


