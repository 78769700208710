<template>
  <div v-click-outside="defocus" class="input-select-wrapper">
    <div @focus="focus" :tabindex="tabindex" :class="{'input-select_focus': selectMode}" class="input-select"
         @click="focus">
      <div class="input-select__arrow"></div>
      <input ref="input_el" v-if="selectMode" :placeholder="placeholder" class="input-select__input" type="text"
             v-model="searchText"/>
      <div v-else-if="selectValue" class="input-select__inner">
        {{ this.selectValue[itemText] }}
      </div>
      <div class="input-select__placeholder" v-else>
        {{ placeholder }}
      </div>
    </div>
    <div v-if="selectMode" class="input-items">
      <div v-for="(item, index) in filteredItems" :key="index" class="input-items__item" @click="select(item)">
        {{ item[itemText] }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "SelectUI",
  computed: {
    filteredItems() {
      return this.searchText.length > 0 ? this.items.filter((item) => {
        return item[this.itemText].indexOf(this.searchText) !== -1
      }) : this.items;
    }
  },
  props: {
    tabindex: {},
    id: {},
    items: {
      default: () => [],
      type: Array
    },
    defaultValue: {
      default: null
    },
    value: {
      default: null
    },
    itemText: String,
    itemValue: String,
    placeholder: String,
  },
  watch: {
    items() {
      this.selectValue = null;
      this.searchText = '';
      this.selectMode = false;
      this.init(this.value);
    }
  },
  methods: {
    init(v) {
      if (!v) return;
      if (this.itemValue) {
        let real_value = this.items.filter((item) => item[this.itemValue] === v)[0];
        if (real_value) {
          this.selectValue = real_value;
          this.searchText = real_value[this.itemText]
        }
      } else {
        this.selectValue = v;
      }
    },
    select(item) {
      this.selectValue = item;
      this.selectMode = false;
      if (item) {
        this.searchText = item[this.itemText];
        this.$emit('input', this.itemValue ? item[this.itemValue] : item)
      } else {
        this.searchText = ''
        this.$emit('input', null)
      }
    },
    focus() {
      this.selectMode = !this.selectMode;
      this.searchText = '';
      setTimeout(() => this.$refs.input_el.focus(), 1);
    },
    defocus() {
      if (!this.selectMode || !this.$refs.input_el) return;
      this.selectMode = false;
      if (!this.searchText.length) {
        this.select(null)
      }
      setTimeout(() => this.$refs.input_el.blur(), 1);
    }
  },
  data() {
    return {
      selectMode: false,
      selectValue: null,
      searchText: ''
    }
  },
  mounted() {
    this.init(this.value);
  },
  beforeDestroy() {
    this.searchText = ''
    this.selectValue = null
    this.searchText = '';
  },
  beforeUpdate() {
    // eslint-disable-next-line no-console
    console.log('rerender') // Logs the counter value every second, before the DOM updates.
  },
}
</script>

<style scoped>

.input-select {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  position: relative;
  background-color: #fefefe;
  padding: 10px 60px 10px 10px;
}


.input-select__inner {
  width: 100%;
  height: 1.5em;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.input-select__arrow {
  display: block;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
  position: absolute;
  z-index: 1000;
  height: 32px;
  width: 32px;
}

.input-select:hover {
  cursor: pointer;
}

.input-select__arrow::before {
  content: '';
  width: 14px;
  height: 2px;
  left: calc(50% + 7px);
  top: calc(50% + 4px);
  background-color: #eaeaea;
  transform: rotate(-135deg) translate(-50%, -50%);
  position: absolute;
  display: block;
  transform-origin: -50% 0%;
  transition: all 0.2s;
}

.input-select__arrow::after {
  content: '';
  width: 14px;
  height: 2px;
  left: calc(50% + 7px);
  top: calc(50% + 4px);
  background-color: #eaeaea;
  transform: rotate(-45deg) translate(-50%, -50%);
  position: absolute;
  display: block;
  transform-origin: -50% 0%;
  transition: all 0.2s;
}


.input-select_focus .input-select__arrow::before {
  top: calc(50% - 3px);
  transform: rotate(-225deg) translate(-50%, -50%);
}

.input-select_focus .input-select__arrow::after {
  top: calc(50% - 3px);
  transform: rotate(45deg) translate(-50%, -50%);
}

.input-select_focus .input-select__arrow::before, .input-select_focus .input-select__arrow::after {
  background-color: #dedede;
}

.input-select_focus {
  border: 1px solid #dedede;
  outline: none;
}

.input-items {
  width: 100%;
  border-radius: 10px;
  background-color: #fefefe;
  top: 50px;
  z-index: 10000;
  position: absolute;
  max-height: 350px;
  overflow: scroll;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
}

.input-items__item {
  padding: 10px;
}

.input-items__item:hover {
  cursor: pointer;
  background: #eaeeef;
}

.input-select-wrapper {
  position: relative;
}


.input-items::-webkit-scrollbar {
  width: 2px; /* width of the entire scrollbar */
  height: 0;
}

.input-items::-webkit-scrollbar-track {
  background: #999999; /* color of the tracking area */
}

.input-items::-webkit-scrollbar-thumb {
  background-color: #eaeeef;; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 1px solid #dedede; /* creates padding around scroll thumb */
}

.input-select__input {
  width: 100%;
  height: 100%;
}

.input-select__placeholder {
  color: #8a8a8a;
}

.input-select__input:focus {
  outline: none;
}

</style>
