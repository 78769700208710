import endpoint from "@/api/endpoint";

export default {

    read(data) {
        return window.axios.get(endpoint + '/api/inboxes/' + data.id + '/read');
    },
    
    count() {
        return window.axios.get(endpoint + '/api/inboxes/count');
    },
}
