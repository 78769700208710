import endpoint from "@/api/endpoint";


export default {
    all(data) {
        return window.axios.post(endpoint + '/api/users', data);
    },

    break_password(data) {
        return window.axios.post(endpoint + '/api/users/' + data.id + '/break_password');
    },

    item(data) {
        return window.axios.get(endpoint + '/api/users/' + data.id);
    },

    events() {
        return window.axios.get(endpoint + '/api/user/events');
    },

    remove(data) {
        return window.axios.post(endpoint + '/api/users/' + data.id + '/remove');
    },

    removeForce(data) {
        return window.axios.post(endpoint + '/api/users/' + data.id + '/removeForce');
    },

    restore(data) {
        return window.axios.post(endpoint + '/api/users/' + data.id + '/restore');
    },
    rating() {
        return window.axios.get(endpoint + '/api/users/rating');
    },

    transactions(data) {
        return window.axios.get(endpoint + '/api/users/' + data.id + '/transactions');
    },

    query(data) {
        return window.axios.get(endpoint + '/api/chats/users/' + data.query, {cancelToken: data.ct});
    },


}
