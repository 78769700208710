import user from "@/api/user";

export default {
    state: {
        currentUser: {},
        currentUserEvents: [],
        error: {},
        fluid: false,
        token: null,
        inboxes: [],
        version_new: null
    },
    mutations: {
        setUser(state, user) {
            state.currentUser = user
        },

        setNewVersion(state, version_new) {
            state.version_new = version_new
        },

        setInboxes(state, inboxes) {
            state.inboxes = inboxes
        },
        setAuthError(state, error) {
            state.error = error;
            state.token = null
        },
        setCurrentUserEvents(state, events) {
            state.currentUserEvents = events;
        },
        setFluid(state, fluid) {
            state.fluid = fluid;
        },

        setToken(state, token) {
            // eslint-disable-next-line no-console
            console.log("SET TOKEN", token);
            localStorage.setItem('access_token', token);
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            state.token = token
        }
    },
    getters: {
        checkPermission: state => (key) => {
            return state.currentUser.id > 0 && state.currentUser.permissions.indexOf(key) !== -1;
        }
    },
    actions: {
        getUser({commit}) {
            return user.getUser().then((r) => {
                commit('setUser', r.data);
                if (window.tracker) {
                    window.tracker.setUserID(r.data.email);
                    window.tracker.setMetadata('university', r.data.uni_id);
                }
            }).catch((err) => {
                if (err.code === 401) {
                    localStorage.removeItem('access_token');
                }
                commit('setAuthError', err);

            });
        },

        // eslint-disable-next-line no-unused-vars
        authUser({commit, dispatch}, {username, password}) {
            return new Promise((resolve, reject) => {
                user.authUser(username, password).then((response) => {
                    // eslint-disable-next-line no-console
                    commit('setToken', response.data.access_token);
                    if (window.Echo) {
                        window.Echo.options.auth.headers['Authorization'] = `Bearer ${response.data.access_token}`;
                    }
                    dispatch('getUser').then(() => {
                        resolve()
                    });

                }).catch((error) => {
                    reject(error);
                    localStorage.removeItem('access_token');
                    commit('setAuthError', error);
                });
            })

            /*
            then(response => {

            }, error => {


            });
             */

        },

        signUp({commit}, user_object) {
            return user.signUp(user_object).then((response) => {
                commit('setUser', response.data);
            })
        },

        loadInboxes({commit}) {
            return user.inboxes().then((r) => {
                return commit('setInboxes', r.data);
            })
        },

        logout({commit}) {
            localStorage.removeItem('access_token');
            commit('setUser', {});

        },


        reset({commit, dispatch}, data) {
            return new Promise((resolve, reject) => {
                user.reset(data).then((r) => {
                    commit('setToken', r.data.token)
                    dispatch('getUser').then(() => {
                        resolve()
                    });
                }).catch((error) => {
                    reject(error);
                })
            })
        },

        getUserEvents({commit}) {
            return user.events().then((response) => {
                commit('setCurrentUserEvents', response.data);
            });
        },


    }
}


