<template>
  <div class="di-wrap">
    <slot></slot>
  </div>

</template>

<script>
import 'vue2-datepicker/index.css';

export default {
  name: "DatePickerUIDecorator",
  props: {
    value: {},

  }
}
</script>

<style>
.date-input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  background-color: #fefefe;
}

.date-input:focus {
  outline: none;
}

.di-wrap {
  position: relative;
}

.mx-datepicker-range, .mx-datepicker {
  width: 100% !important;
}

</style>
