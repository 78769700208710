import Vue from 'vue'
import App from './components/v2/App.vue'
import 'vuetify/dist/vuetify.min.css'
import VueRouter from 'vue-router'
import {router} from "@/routes";
import index from "@/store";
import Vuex from 'vuex'
import moment from "moment";
import picker from "vue-datetime-picker";
import 'moment/locale/ru'
import plural from '@/plural.js'
import vuetify from "@/plugins/vuetify";

Vue.prototype.$moment = moment;
Vue.prototype.$plural = plural;
Vue.prototype.$moment.locale('ru');
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(picker);




new Vue({
    render: h => h(App),
    router: router,
    store: index,
    vuetify: vuetify,
    mounted() {
        setInterval(() => {
            window.axios.get('/v.txt').then((r) => {
                if(process.env.VUE_APP_RELEASE_VERSION && parseInt(process.env.VUE_APP_RELEASE_VERSION) < parseInt(r.data)){
                   index.commit('setNewVersion', r.data);
                }
            })
        }, 5000);


    }
}).$mount('#app')



