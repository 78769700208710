import endpoint from "@/api/endpoint";

import FingerprintJS from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load()

;(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    window.visitorId = result.visitorId
})()


export default {

    event(data) {
        return window.axios.post(endpoint + '/api/analytics/' + data.event, data);
    },


    event_ch(data) {
        let utm = new URL(window.location.href.replace('#/', '')).searchParams;
        return window.axios.post(endpoint + '/api/analytics_ch', {
            ...data,
            visitor_id: window.visitorId,
            utm: utm.get('utm')
        });
    },

    openProjectPageEvent(project_id, user_id) {
        return this.event_ch({
            event_code: 'OPEN_PROJECT_PAGE',
            project_id, user_id
        });
    },

    openProjectTasksEvent(project_id, user_id) {
        return this.event_ch({
            event_code: 'OPEN_PROJECT_TASKS_PAGE',
            project_id, user_id
        });
    },

    openProjectTaskEvent(task_id, project_id, user_id, origin = '') {
        return this.event_ch({
            event_code: 'OPEN_PROJECT_TASK',
            project_id, user_id, task_id, origin
        });
    },

    openProjectsPageEvent(user_id) {
        return this.event_ch({
            event_code: 'OPEN_PROJECTS_PAGE',
            user_id
        });
    },

    openHomePageEvent(user_id) {
        return this.event_ch({
            event_code: 'OPEN_HOME_PAGE',
            user_id
        });
    },

    openVacancies(user_id) {
        return this.event_ch({
            event_code: 'OPEN_VACANCIES_PAGE',
            user_id
        });
    },

    openMaterialPage(material_id, user_id) {
        return this.event_ch({
            event_code: 'OPEN_MATERIAL_PAGE',
            user_id, material_id
        });
    },

    openEditMaterialPage(material_id, user_id) {
        return this.event_ch({
            event_code: 'OPEN_EDIT_MATERIAL_PAGE',
            user_id, material_id
        });
    },

    openAllTasksPage(user_id) {
        return this.event_ch({
            event_code: 'OPEN_ALL_TASKS_PAGE',
            user_id
        });
    },

    openCreateTaskPage(user_id) {
        return this.event_ch({
            event_code: 'OPEN_CREATE_TASK_PAGE',
            user_id
        });
    },

    openLoginPage(user_id) {
        return this.event_ch({
            event_code: 'OPEN_LOGIN_PAGE',
            user_id
        });
    },

    openProjectCreatePage(user_id) {
        return this.event_ch({
            event_code: 'OPEN_PROJECT_CREATE',
            user_id
        });
    },

    openOpdPage(user_id) {
        return this.event_ch({
            event_code: 'OPEN_OPD_PAGE',
            user_id
        });
    },

    openOpdGroupPage(user_id) {
        return this.event_ch({
            event_code: 'OPEN_OPD_GROUP_PAGE',
            user_id
        });
    },

    openProfilePage(user_id) {
        return this.event_ch({
            event_code: 'OPEN_PROFILE_PAGE',
            user_id
        });
    },

    submitProjectCreatePage(user_id) {
        return this.event_ch({
            event_code: 'OPEN_PROJECT_CREATE',
            user_id
        });
    },

    taskCreated(user_id, task_id) {
        return this.event_ch({
            event_code: 'TASK_CREATED',
            user_id,
            task_id
        });
    },

    openEventPage(user_id, event_id) {
        return this.event_ch({
            event_code: 'OPEN_EVENT_PAGE',
            user_id,
            event_id
        });
    },


    postViewed(user_id, post_id) {
        return this.event_ch({
            event_code: 'POST_VIEWED',
            user_id,
            post_id,
        });
    },

    openHostingPage(user_id) {
        return this.event_ch({
            event_code: 'OPEN_HOSTING_PAGE',
            user_id
        });
    },
    createDB(user_id) {
        return this.event_ch({
            event_code: 'CREATE_HOSTING_DB',
            user_id
        });
    },
    destroyDB(user_id) {
        return this.event_ch({
            event_code: 'DESTROY_HOSTING_DB',
            user_id
        });
    },


}
