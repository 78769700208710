import endpoint from "@/api/endpoint";

export default {

    info(hostname) {
        return window.axios.get(endpoint + '/api/university/info', {params: {hostname}});
    },

    hs(){
        return window.axios.get(endpoint + '/health');
    }

}
