import endpoint from "@/api/endpoint";

export default {
    all() {
        return window.axios.get(endpoint + '/api/institutes');
    },

    create(name) {
        return window.axios.post(endpoint + '/api/institutes', {name});
    },

    update(id, name) {
        return window.axios.post(endpoint + '/api/institutes/'+id, {name});
    },

    delete(id) {
        return window.axios.post(endpoint + '/api/institutes/'+id + '/delete');
    }
}
