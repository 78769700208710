<template>
  <div :class="{'messenger_full': !min_version}"
       :style="`width: ${width}; right: 0; bottom: 0; ${full ? 'position: relative; z-index:1;': ''}`"
       class="messenger">
    <div class="d-flex flex-column" :style="`height: ${!min_version ? height: '47px'}`">
      <v-app-bar max-height="48" color="primary" dark dense flat>
        <v-btn v-if="select_chat_id !== null" icon
               @click="select_chat_id=null; title='Чаты'; $store.dispatch('getChats');  $store.dispatch('loadInboxes'); $store.dispatch('getUnreadCount');">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title style="font-size: 14px;">
          {{ title }}
          <v-badge  v-if="$store.state.chats.unreadCount > 0 && select_chat_id == null"
                   :content="$store.state.chats.unreadCount"
                   :value="$store.state.chats.unreadCount"
                   color="red"
                   inline
          ></v-badge>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!min_version && !full" icon
               @click="min_version=true; $store.dispatch('getChats');  closeAnalytics()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn v-else-if="!full" icon
               @click="min_version=false; $store.dispatch('getChats'); $store.dispatch('loadInboxes'); $store.dispatch('getUnreadCount'); openAnalytics()">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        <v-btn v-else-if="full" icon
               @click="$router.back()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-app-bar>
      <div class="mt-1" v-if="!min_version && select_chat_id === null">
        <InputUI :loading="searchLoading" v-model="search"
                 placeholder="Поиск"/>
      </div>
      <div style="overflow-y: scroll; display: flex; flex-direction: column"  v-if="!min_version && search.length === 0">
        <div style="height: 100%;" v-if="loading" class="pa-4 mt-2 d-flex justify-center" >
          <v-progress-circular color="primary" indeterminate width="2"/>
        </div>
        <div  style="height: 100%; background: white;" v-else>
          <div v-if="select_chat_id === null">
              <v-list-item v-if="$store.state.user.inboxes.length > 0"
                           @click="select_chat_id = -1; title = 'Уведомления'">
                <v-list-item-avatar>
                  <v-img
                      :src="'https://ui-avatars.com/api/?name=У&rounded=true&background=1976d2&color=ffffff'"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Уведомления<span
                      class="grey--text text--lighten-1 float-right">
                                        <v-badge
                                            :content="$store.state.user.inboxes.filter((el) => el.read_at === null).length"
                                            :value="$store.state.user.inboxes.filter((el) => el.read_at === null).length"
                                            color="red"
                                            inline
                                        ></v-badge>
                                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $store.state.user.inboxes[0].text }}
                  </v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>

              <ChatItemComponent @select_chat="select_chat_id = $event.id" v-for="chat in chats" :key="chat.id"
                                 :chat="chat"/>
          </div>
          <div v-else-if="select_chat_id === -1" style="width: 100%; height: 100%">
            <Inboxes/>
          </div>
          <SmartChatComponent v-else :chat_id="select_chat_id" :height="320" style="width: 100%"
                              @chatNameUpdate="title = $event"
                              @chatUpdated="min_version = false"/>
        </div>
      </div>
      <div  v-if="!min_version && search.length > 0">
        <v-list-item @click="newChat(person)" v-for="person in peoples" :key="person.id">
          <v-list-item-avatar v-if="person">
            <v-img :src="person.avatar"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ person.last_name }} {{ person.first_name }}
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </div>
    </div>

  </div>
</template>

<script>
//import DialogComponent from "@/components/Messages/DialogComponent.vue";
import SmartChatComponent from "@/components/Messages/SmartChatComponent.vue";
import ChatItemComponent from "@/components/Messages/ChatItemComponent.vue";
import users from "@/api/users.js";
import chats from "@/api/chats.js";
import analytics from "@/api/analytics.js";

import Inboxes from "@/components/Messages/Inboxes.vue";
import InputUI from "@/components/UI/InputUI.vue";


export default {
  name: "FloatMessenger",
  components: {InputUI, ChatItemComponent, SmartChatComponent, Inboxes},
  props: ['full', 'height', 'chat_user_id'],
  computed: {
    chats() {
      return this.$store.state.chats.chats;
    },
    showChat() {
      return this.$store.state.chats.showChat;
    },
    width() {
      if (this.full) {
        return '100%'
      }
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return "100%"
        case 'sm':
          return 400
        case 'md':
          return 500
        case 'lg':
          return 600
        case 'xl':
          return 800
      }
      return "100%";
    },
  },
  watch: {
    showChat(){
      this.min_version = true;
    },
    "$route.query.chat_user_id"() {
      if (this.$route.query.chat_user_id) {
        this.min_version = false;
        this.newChat({id: this.$route.query.chat_user_id})
      }
    },
    chat_user_id() {
      if (this.chat_user_id) {
        this.min_version = false;
        this.newChat({id: this.chat_user_id})
      }
    },
    search() {
      if (this.search.length > 0) {
        // eslint-disable-next-line no-console
        console.log(this.searchLoading)
        if (this.searchLoading) {
          this.source.cancel();
        }
        this.searchLoading = true;
        this.source = this.CancelToken.source();
        users.query({query: this.search, ct: this.source.token}).then((r) => {
          this.peoples = r.data;
          this.searchLoading = false;
        }).catch(() => {
          this.searchLoading = false;
        })

      }
    }
  },
  data() {
    return {
      loading: true,
      min_version: false,
      select_chat_id: null,
      searchLoading: false,
      title: "Чаты",
      messageToSend: "",
      search: "",
      peoples: [],
      CancelToken: null,
      source: null
    }
  },
  methods: {
    closeAnalytics() {
      analytics.event({event: 'CHAT_CLOSE_FLOAT', details: {}});
    },
    openAnalytics() {
      analytics.event({event: 'CHAT_OPEN_FLOAT', details: {}});
    },
    newChat(person) {
      this.loading = true;
      chats.create({users: [person.id]}).then((r) => {
        this.search = "";
        this.loading = false;
        this.chat_user_id = null;
        this.select_chat_id = r.data.id;
      });
    }
  },
  mounted() {
    if(!this.$store.state.user.currentUser.id) this.$router.push('/auth');
    this.CancelToken = window.axios.CancelToken;
    this.source = this.CancelToken.source();

    if (this.$route.query.chat_user_id) {
      this.chat_user_id = this.$route.query.chat_user_id;
    }
    this.$store.dispatch('getChats').then(() => {
      // this.select_chat = this.chats[0]
      this.loading = false;
    });
    if ((this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name == 'sm') && !this.full) {
      this.min_version = true;
      this.$store.dispatch('getChats');
    }

    this.$store.dispatch('loadInboxes');


    if (window.Echo) {
      window.Echo.private(`users.${this.$store.state.user.currentUser.id}`)
          .listen('user_notify', () => {

            Promise.all([
              this.$store.dispatch('loadInboxes'),
              this.$store.dispatch('getChats')
            ]).then(() => {
              document.title = "АИС | Новое уведомление";
              new Audio('/notification.mp3').play();
              this.min_version = false;
            });

          });
    }
  },
  beforeDestroy() {
    this.$store.dispatch('getUnreadCount');
    if(window.Echo){
      window.Echo.leave(`users.${this.$store.state.user.currentUser.id}`)
    }

  }
}
</script>

<style scoped>


.messenger {
  width: 400px;
  bottom: 0;
  z-index: 100;
  position: fixed;
  right: 1em;
  overflow-y: hidden;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.5);
}


.fade-enter-active, .fade-leave-active {
  transition: max-height 5s;
}

.fade-enter, .fade-leave-to {
  max-height: 0;
}

.messenger_full {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background: white;
  max-height: calc(100vh - 48px)
}

</style>
