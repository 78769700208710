<template>
  <div class="card">
    <div v-if="!!this.$slots.header" class="card__header">
      <slot name="header"></slot>
    </div>
    <div :style="`${contentHeight ? `max-height: ${contentHeight}; overflow-y: scroll;` : ''}`" class="card__body">
      <slot name="body"></slot>
    </div>
    <div v-if="!!this.$slots.footer" class="card__header">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardUI",
  props: ['contentHeight']
}
</script>

<style scoped>
.card {
  background: white;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
  border-radius: 20px;
  overflow: hidden;
}

.card__header {
  background-color: #fafafa;
  height: 60px;
  display: flex;
  align-items: center;

}

.card__body, .card__header {
  padding: 10px;
}
</style>
