<template>
  <div class="service_message">
    <div class="service_message__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmartServiceMessage"
}
</script>

<style scoped>
.service_message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.service_message__text {
  background: #a8e3ff;
  display: inline-block;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
  padding: 5px 10px;
  font-size: 12px;
  opacity: 0.8;
}
</style>
