<template>
  <div>
    <div v-if="!readOnly && (multiple || files.length < 1)" ref="fileform" :draggable="true" class="upload-form"
         @click="$refs.input.click()">
      <input ref="input" multiple style="display: none" type="file" @input="uploadByClick"/>
      <div>
        <v-icon left>mdi-cursor-default-click</v-icon>
        Выберите
        <template v-if="!multiple">файл</template>
        <template v-else>файлы</template>
      </div>
    </div>
    <div v-else-if="files.length < 1">
      Нет загруженных файлов
    </div>
    <div class="mt-3">
      <div v-for="(file, index) in files" :key="index" class="file">
        <div v-if="file.is_local">
          <v-progress-circular :size="14" :value="file.progress" class="mr-2" color="primary" width="1"/>
          {{ file.name }}
          <div v-if="file.error" style="color: red; float: right">
            {{ file.error }}
            <v-btn v-if="!readOnly" color="red" plain x-small @click.prevent.stop="remove(index)">
              <v-icon left>mdi-close</v-icon>
            </v-btn>
          </div>

        </div>
        <div v-else class="file-item">
          <v-btn v-if="!readOnly" color="red" plain rounded x-small @click.prevent.stop="remove(index)">
            <v-icon left>mdi-close</v-icon>
          </v-btn>
          <div class="d-flex align-center">
            <div v-if="~['jpeg', 'jpg', 'png', 'svg'].indexOf(file.link.split('.').pop())">
              <v-img aspect-ratio="1" :src="file.link" height="64" width="64"/>
            </div>
            <div class="ml-4">
              {{ file.filename }}
            </div>
          </div>
          <v-spacer/>

          <v-btn :href="file.link" class="float-right" color="primary" plain target="_blank" x-small>
            <v-icon left>mdi-link</v-icon>
            Открыть
          </v-btn>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import files from "@/api/files.js";

export default {
  name: "FileUpload",
  props: {
    value: {},
    readOnly: {},
    multiple: {
      default: false,
      type: Boolean
    },
    onlyTypes: {
      default: '*'
    }
  },
  methods: {
    uploadByClick(event) {
      Array.from(event.target.files).forEach((file) => {
        this.uploadFile(file);
      });
    },
    remove(index) {
      let files = this.files.slice();
      files.splice(index, 1)
      this.files = files;
      this.$emit('input', this.files.slice().filter((f) => f.is_local === undefined))
    },

    fileProgress(vid) {
      return (event) => {
        let progress = Math.round((event.loaded * 100) / event.total);
        this.files = this.files.slice().map((f) => f.id === vid ? {...f, progress} : f);
      };
    },

    uploadFile(file) {
      if (this.onlyTypes !== '*') {
        if (!file.type.match(this.onlyTypes)) {
          alert("Файл в неверном формате")
          return;
        }
      }
      const vid = Math.random().toString(16).slice(2);
      this.files.push({
        id: vid,
        is_local: true,
        file: file,
        name: file.name,
        progress: 0
      });

      let bodyFormData = new FormData();
      bodyFormData.append('file', file);
      files.store(bodyFormData, this.fileProgress(vid)).then((r) => {
        this.files = this.files.slice().map((f) => f.id === vid ? r.data : f);
        this.$emit('input', this.files.slice().filter((f) => f.is_local === undefined))
      }).catch(e => {
        if (e.response.status === 413) {
          this.files = this.files.slice().map((f) => f.id === vid ? {
            ...f,
            error: 'Превышен максимальный размер файла'
          } : f);
        }
      })
    },
  },
  data() {
    return {
      files: []
    }
  },
  mounted() {
    this.files = this.value.slice();
    if(this.$refs.fileform) {
      this.$refs.fileform.addEventListener('dragover', function (e) {
        e.preventDefault();
        this.$refs.fileform.classList.add('drag-active');
      }.bind(this));

      this.$refs.fileform.addEventListener('dragleave', function (e) {
        e.preventDefault();
        this.$refs.fileform.classList.remove('drag-active');
      }.bind(this));

      this.$refs.fileform.addEventListener('drop', function (e) {
        e.preventDefault();
        e.stopPropagation();

        this.$refs.fileform.classList.remove('drag-active');
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          this.uploadFile(e.dataTransfer.files[i]);
          if (!this.multiple) break;
        }

      }.bind(this));
    }
  }
}
</script>

<style scoped>
.upload-form {
  width: 100%;
  height: 70px;
  border: 1px dashed #767d83;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  z-index: 100;
}

.file {
  background: #eaeaea;
  padding: 10px;
  border-radius: 10px;
  margin-top: 1em;
}

.drag-active {
  border-color: #0090ff;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
