<template>
  <div class="inbox">
    <div class="inbox__time">
      {{ $moment.utc(inbox.created_at).local().format("DD.MM HH:mm") }}
    </div>
    <div :key="i" v-for="(line, i) in content.introLines">
      {{ line }}
    </div>
    <v-btn v-if="content.actionUrl && content.actionText" :href="content.actionUrl" outlined color="primary" x-small>
      {{ content.actionText }}
    </v-btn>

  </div>
</template>

<script>
import inboxes from "@/api/inboxes.js";

export default {
  name: "InboxItem",
  computed: {
    content() {
      return JSON.parse(this.inbox.content);
    }
  },
  props: ['inbox'],
  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.inbox);
    if (this.inbox.read_at == null) {
      inboxes.read({id: this.inbox.id});
    }
  }
}
</script>

<style scoped>
.inbox {
  background: #efefef;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
}

.inbox__time {
  font-size: 12px;
}
</style>
