<template>
  <div :class="{'selector_mobile': $vuetify.breakpoint.smAndDown}" class="selector">
    <div @click="select(item.value)" class="selector__part"
         :class="{'selector__part_active': selected_value === item.value}"
         v-for="item in items" :key="item.value">
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectorUI",
  props: ['items', 'defaultValue'],
  data() {
    return {
      selected_value: -1
    }
  },
  methods: {
    select(value) {
      this.selected_value = value
      this.$emit('input', value);
    }
  },
  mounted() {
    this.select(this.defaultValue)
  }
}
</script>

<style scoped>

.selector {
  font-size: 14px;
  display: inline-flex;
  min-height: 32px;
  border-radius: 10px;
  border: 1px solid #c6def1;
  background-color: #fefefe;
  overflow: hidden;
}

.selector__part:first-child {
  border-left: none;
}

.selector__part {
  padding: 10px;
}

.selector__part:hover {
  cursor: pointer;
}

.selector__part_active {
  background-color: var(--accent-color, #0090ff);
  color: white;
}

.selector_mobile {
  flex-direction: column;
  width: 100%;
  border: 1px solid #c6def1;
}

.selector__part {
  border-left: 1px solid #eaeeef;;
}

.selector_mobile .selector__part {
  border-left: 0 solid #eaeeef;;
  border-bottom: 1px solid #c6def1;
}

.selector_mobile .selector__part_active {
  border-bottom: 1px solid #eaeeef;
}

</style>
