import endpoint from "@/api/endpoint";

export default {

    getAll(event_id) {
        return window.axios.get(endpoint + '/api/event/' + event_id + '/teams');
    },

    create(data) {
        return window.axios.post(endpoint + '/api/event/' + data.event_id + '/teams/create', data);
    },

    delete(data) {
        return window.axios.post(endpoint + '/api/teams/' + data.team_id + '/delete');
    },

    join(team_id) {
        return window.axios.post(endpoint + '/api/teams/' + team_id + '/join')
    },

    acceptMember(team_id, user_id) {
        return window.axios.post(endpoint + '/api/teams/' + team_id + '/participants/' + user_id + '/accept');
    },

    openStatus(team_id, open) {
        return window.axios.post(endpoint + '/api/teams/' + team_id + '/open_status', {open});
    },

    changeSlot(team_id, slot_id) {
        return window.axios.post(endpoint + '/api/teams/' + team_id + '/slot', {slot_id});
    },

    hide(team_id, hide) {
        return window.axios.post(endpoint + '/api/teams/' + team_id + '/hide', {hide});
    },

    update(team_id, data) {
        return window.axios.post(endpoint + '/api/teams/' + team_id + '/update', data);
    },

    declineMember(team_id, user_id) {
        return window.axios.post(endpoint + '/api/teams/' + team_id + '/participants/' + user_id + '/decline');
    },

    userTeam(event_id) {
        return window.axios.get(endpoint + '/api/event/' + event_id + '/user_team');
    },

    item(team_id){
        return window.axios.get(endpoint+'/api/teams/'+team_id);
    }
}
