import endpoint from "@/api/endpoint";

export default {
    all() {
        return window.axios.get(endpoint + '/api/project_pools');
    },

    item(data) {
        return window.axios.get(endpoint + '/api/project_pools/'+data.id);
    },

    create(name) {
        return window.axios.post(endpoint + '/api/project_pools', {name});
    },

    update(data) {
        return window.axios.post(endpoint + '/api/project_pools/' + data.id, data);
    },

    removeGroupFromPool(data) {
        return window.axios.post(endpoint + '/api/project_pools/' + data.id + '/removeGroup', data);
    },

    getFreeUsers(data) {
        return window.axios.get(endpoint + '/api/project_pools/' + data.id + '/freeUsers');
    },

    getReport(data) {
        return window.axios.get(endpoint + '/api/project_pools/' + data.id + '/report');
    },

    createPreset(data) {
        return window.axios.post(endpoint + '/api/project_pools/' + data.id + '/presets', data);
    },

    deployPreset(data) {
        return window.axios.post(endpoint + '/api/presets/' + data.id + '/deploy');
    },

    getPresets(data) {
        return window.axios.get(endpoint + '/api/project_pools/' + data.id + '/presets');
    },

    getProblems(data) {
        return window.axios.get(endpoint + '/api/project_pools/' + data.id + '/problems');
    }
}
