import HostingPageComponent from "@/components/Hosting/HostingPageComponent";

const EventsComponent = () => import("@/components/OldEvents/EventsComponent");
import VueRouter from 'vue-router'

const TeamComponent = () => import("@/components/OldEvents/TeamComponent");
const AuthByToken = () => import("@/components/AuthByToken");
const EventParticipantComponent = () => import("@/components/OldEvents/EventParticipants/EventParticipantsComponent");
const EventRubricsComponent = () => import("@/components/OldEvents/EventRubricsComponent");
const DisciplineCreatorIndex = () => import("@/components/Timetables/DisplinesCreator/DisciplineCreatorIndex");
const EditProfileComponent = () => import("@/components/Profile/EditProfileComponent");
const ProjectsListComponent = () => import("@/components/Projects/ProjectsListComponent");
const ProjectsCreateComponent = () => import("@/components/Projects/ProjectsCreateComponent");
const UsersListComponent = () => import("@/components/Admin/Users/UsersListComponent");
const EventsCreateComponent = () => import("@/components/OldEvents/EventsCreateComponent");
const ProjectsItemComponent = () => import("@/components/Projects/ProjectsItemComponent");
const ProjectsItemInfoComponent = () => import("@/components/Projects/ProjectsItem/ProjectsItemInfoComponent");
const ProjectsItemParticipantsComponent = () => import("@/components/Projects/ProjectsItem/ProjectsItemParticipantsComponent");
const ProjectsItemRolesComponent = () => import("@/components/Projects/ProjectsItem/ProjectsItemRolesComponent");
const EventRateComponent = () => import("@/components/OldEvents/EventRateComponent");
const ResetComponent = () => import("@/components/ResetComponent");
const EventChecksComponent = () => import("@/components/OldEvents/EventParticipants/EventChecksComponent");
const ProjectsItemFilesComponent = () => import("@/components/Projects/ProjectsItem/ProjectsItemFilesComponent");
const ProfileComponent = () => import("@/components/Profile/ProfileComponent");
const ProjectsItemChatComponent = () => import("@/components/Projects/ProjectsItem/ProjectsItemChatComponent.vue");
const ProjectsItemTrelloComponent = () => import("@/components/Projects/ProjectsItem/ProjectsItemTrelloComponent.vue");
const ProjectsItemCalendarComponent = () => import("@/components/Projects/ProjectsItem/ProjectsItemCalendarComponent.vue");
const ProjectsItemPassportComponent = () => import("@/components/Projects/ProjectsItem/ProjectItemPassportComponent.vue");
const ProjectsItemCostsComponent = () => import("@/components/Projects/ProjectsItem/ProjectItemCostsComponent.vue");
import analytics from "@/api/analytics";
import index from "./store/index.js"
import EventEditWidget from "@/components/Events/EventParts/Widgets/EventEditWidget";

const MainBoardTasksComponent = () => import("@/components/Tasks/MainBoardTasksComponent.vue");
const EventItemInfo = () => import("@/components/Events/EventParts/EventItemInfo.vue");
const EventItemOldComponent = () => import("@/components/OldEvents/EventItemOldComponent.vue");
const EventTeamPoints = () => import("@/components/Events/EventParts/EventTeamPoints.vue");
const PageNotFound = () => import("@/components/PageNotFound.vue");
const ConfirmationPhoneComponent = () => import("@/components/Profile/ConfirmationPhoneComponent.vue");


const FloatMessenger = () => import("@/components/Messages/FloatMessenger.vue");
const ProjectPoolsIndexComponent = () => import("@/components/Admin/ProjectPools/ProjectPoolsIndexComponent.vue");
const ProjectsAnalytics = () => import("@/components/Analytics/ProjectsAnalytics");
const ProjectPool = () => import("@/components/Admin/ProjectPools/ProjectPool");
const ProjectItemFeedComponent = () => import("@/components/Projects/ProjectsItem/ProjectItemFeedComponent")
const Feed = () => import("@/components/Feed/Feed");
const Homepage = () => import("@/components/Dashboard/Homepage");
const ProjectsItemExamsComponent = () => import("@/components/Projects/ProjectsItem/ProjectsItemExamsComponent");

const RatingsComponent = () => import('@/components/Dashboard/RatingsComponent.vue')
const LoginComponent = () => import('@/components/LoginComponent.vue')
const SignupComponent = () => import('@/components/SignupComponent.vue')
const MyTimetableComponent = () => import('@/components/Timetables/MyTimetableComponent')
const TimetableComponent = () => import('@/components/Timetables/TimetableComponent')
const AuthComponent = () => import('@/components/AuthComponent')
const MaterialPage = () => import('@/components/Materials/MaterialPage.vue')
const MaterialEditor = () => import('@/components/Materials/MaterialEditor.vue')
const VacanciesPage = () => import('@/components/Vacancies/VacanciesListComponent')
const OpdGroupPageComponent = () => import('@/components/Opd/OpdGroupPageComponent.vue')
const TeamSorted = () => import('@/components/Events/TeamSorted.vue')
const Meetings = () => import('@/components/Meets/Meetings.vue')
const AdminIndexComponent = () => import('@/components/Admin/AdminIndexComponent.vue')
const AdminGroupsComponent = () => import('@/components/Admin/Groups/AdminGroupsComponent.vue')
const AdminDepartmentsComponent = () => import('@/components/Admin/Departments/AdminDepartmentsComponent.vue')
const OpdComponent = () => import('@/components/Opd/OpdComponent.vue')

const routes = [
    {path: '/', component: Homepage, name: 'home'},
    {path: '/ratings', component: RatingsComponent, meta: {'title': 'Рейтинг'}, name: 'ratings'},
    {path: '/events', component: EventsComponent, name: 'events', meta: {'title': 'Мероприятия'},},
    {
        path: '/events/my',
        component: EventsComponent,
        name: 'user.events',
        meta: {'title': 'Мои мероприятия'},
        props: {my: true}
    },
    {
        path: '/events/create',
        component: EventsCreateComponent,
        meta: {'title': 'Создание мероприятия'},
        name: 'user.events'
    },
    {path: '/events/:id/info', component: EventItemInfo, name: 'events.item.info'},
    {path: '/events/:id/expert', component: EventTeamPoints, name: 'events.expert'},
    {path: '/events/:id/sort', component: TeamSorted, name: 'events.sort'},
    {
        path: '/admin', component: AdminIndexComponent, name: 'admin',
        children: [
            {path: '/', redirect: '/admin/users'},
            {path: 'users', component: UsersListComponent, name: 'admin.users'},
            {path: 'groups', component: AdminGroupsComponent, name: 'admin.groups'},
            {path: 'opd', component: ProjectPoolsIndexComponent, name: 'admin.opd'},
            {path: 'opd/pool/:id', component: ProjectPool, name: 'admin.opd.pool'},
            {path: 'departments', component: AdminDepartmentsComponent, name: 'admin.departments'},
        ]
    },
    {
        path: '/events/:id/admin/', component: EventItemOldComponent, name: 'events.item',
        children:
            [
                {path: '/', redirect: 'info'},
                {path: 'edit', component: EventEditWidget, name: 'event.edit'},
                {path: 'participants', component: EventParticipantComponent, name: 'event.participants'},
                {path: 'info', component: EventItemInfo, name: 'events.item.info'},
                {path: 'checks', component: EventChecksComponent, name: 'events.item.checks'},
                {path: 'criteria', component: EventRubricsComponent, name: 'event.criteria'},
                {path: 'rating', component: EventRateComponent, name: 'event.rating'},
            ]
    },
    {path: '/events/:id/teams/:team_id', component: TeamComponent, name: 'teams.item'},
    {path: '/auth', component: AuthComponent},
    {path: '/auth/login', component: LoginComponent, name: 'login'},
    {path: '/auth/signup', component: SignupComponent},
    {path: '/reset/:token', component: ResetComponent},
    {path: '/timetable', component: TimetableComponent},
    {path: '/hosting', component: HostingPageComponent, name: 'hosting'},
    {path: '/timetable/my/:id?', component: MyTimetableComponent},
    {path: '/timetable/create/master', component: DisciplineCreatorIndex, name: 'timetable.master'},
    {path: '/authByToken/:token', component: AuthByToken},
    {path: '/profile/:id/edit', component: EditProfileComponent, name: 'profile.edit'},
    {path: '/profile/:id', component: ProfileComponent, name: 'profile'},
    {path: '/feed', component: Feed, name: 'feed'},
    {path: '/projects', component: ProjectsListComponent, name: 'projects', meta: {'title': 'Проекты'},},
    {
        path: '/projects_my',
        component: ProjectsListComponent,
        props: {my: 1},
        name: 'projects_my',
        meta: {'title': 'Мои проекты'},
    },
    {path: '/materials/creator/:id?', component: MaterialEditor, name: 'materials.creator'},
    {path: '/materials/:id', component: MaterialPage, name: 'materials.page'},
    {path: '/vacancies', component: VacanciesPage, name: 'vacancies.page'},
    {path: '/analytics/projects', component: ProjectsAnalytics, name: 'analytics.projects'},
    {path: '/projects/create', component: ProjectsCreateComponent, meta: {'title': 'Создание проекта'}},
    {
        path: '/confirmation_phone',
        component: ConfirmationPhoneComponent,
        meta: {'title': 'Необходимо подтвердить телефон'}
    },
    {
        path: '/projects/:project_id', component: ProjectsItemComponent, children: [
            {path: '/', redirect: 'feed'},
            {path: 'feed', component: ProjectItemFeedComponent, name: 'project.feed'},
            {path: 'info', component: ProjectsItemInfoComponent, name: 'project.info'},
            {path: 'roles', component: ProjectsItemRolesComponent, name: 'project.roles'},
            {path: 'participants', component: ProjectsItemParticipantsComponent, name: 'project.participants'},
            {path: 'files', component: ProjectsItemFilesComponent, name: 'project.files'},
            {path: 'chat', component: ProjectsItemChatComponent, name: 'project.chat'},
            {path: 'trello', component: ProjectsItemTrelloComponent, name: 'project.trello'},
            {path: 'calendar', component: ProjectsItemCalendarComponent, name: 'project.calendar'},
            {path: 'exams', component: ProjectsItemExamsComponent, name: 'project.exams'},
            {path: 'passport', component: ProjectsItemPassportComponent, name: 'project.passport'},
            {path: 'costs', component: ProjectsItemCostsComponent, name: 'project.costs'},
        ]
    },
    {
        path: '/tasks', component: MainBoardTasksComponent, meta: {'title': 'Задачи'}, children: []
    },
    {
        path: '/tasks/:id', component: MainBoardTasksComponent, props: (route) => ({id: route.params.id}), children: []
    },
    {
        path: '/messages',
        meta: {'title': 'Сообщения'},
        name: 'messages',
        component: FloatMessenger,
        props: {full: true, height: 'calc(100vh - 60px)'}
    },
    {path: '/meets', component: Meetings},
    {path: '/opd', component: OpdComponent, name: 'opd'},
    {path: '/opd/group/:id', component: OpdGroupPageComponent},
    {path: "*", component: PageNotFound}
]

// 3. Создаём экземпляр маршрутизатора и передаём маршруты в опции `routes`
// Вы можете передавать и дополнительные опции, но пока не будем усложнять.
export const router = new VueRouter({
    routes
});


router.afterEach((to) => {
    // document.title = to.meta.title ? 'АИС | ' + to.meta.title : 'АИС "Студент СурГУ"';
    if (index.state.user.currentUser.id > 0) {
        analytics.event({
            event: 'SCREEN_VISITED',
            details: {route: {name: to.name, path: to.path, query: to.query, params: to.params}}
        })
    }
})

router.beforeEach((to, from, next) => {
    // document.title = to.meta.title ? 'АИС | ' + to.meta.title : 'АИС "Студент СурГУ"';
    // eslint-disable-next-line no-console
    if(index.state.user.currentUser.necessary_fields && index.state.user.currentUser.necessary_fields.redirect && to.name !== 'profile.edit') {
        next({
            name: 'profile.edit',
            params: {
                id:  index.state.user.currentUser.id
            }
        })
    } else {
        next();
    }
})
