<template>
  <div class="empty">
    <div class="pa-4">
      <v-icon size="40">
        mdi-flask-empty-outline
      </v-icon>
    </div>

    <h4>{{ title }}</h4>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "Empty",
  props: ['title', 'description']
}
</script>

<style scoped>
.empty {
  padding: 20px;
  background: #f6f6f6;
  border-radius: 10px;
  text-align: center;
}
</style>
