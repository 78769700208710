<template>
  <div class="container" style="height: 100%; overflow-y: scroll; ">
    <InboxItem v-for="inbox in inboxes" :inbox="inbox" :key="inbox.id"/>
  </div>
</template>

<script>
import InboxItem from "@/components/Messages/InboxItem.vue";

export default {
  name: "Inboxes",
  components: {InboxItem},
  computed: {
    inboxes() {
      return this.$store.state.user.inboxes;
    }
  }
}
</script>

<style scoped>
.container::-webkit-scrollbar {
  width: 0;
}
</style>
