
import endpoint from "@/api/endpoint";

export default {

    update(data) {
        return window.axios.post(endpoint + '/api/tasks/' + data.id, data);
    },

    store(data) {
        return window.axios.post(endpoint + '/api/tasks', data);
    },

    item(data) {
        return window.axios.get(endpoint + '/api/tasks/' + data.id);
    },

    comments(data) {
        return window.axios.get(endpoint + '/api/tasks/' + data.id + '/comments');
    },

    addComment(data) {
        return window.axios.post(endpoint + '/api/tasks/' + data.id + '/addComment', data);
    },
    observe(data) {
        return window.axios.get(endpoint + '/api/tasks/' + data.id + '/observe');
    },

    unobserve(data) {
        return window.axios.get(endpoint + '/api/tasks/' + data.id + '/unobserve');
    },

    user_tasks(data) {
        return window.axios.get(endpoint + '/api/users/' + data.id + '/tasks');
    },

}
