import endpoint from "@/api/endpoint";
export default {
    all() {
        return window.axios.get(endpoint + '/api/departments');
    },

    create(name, institute_id) {
        return window.axios.post(endpoint + '/api/departments', {name, institute_id});
    },
    update(name, id) {
        return window.axios.post(endpoint + '/api/departments/'+id, {name});
    },
    delete(id) {
        return window.axios.post(endpoint + '/api/departments/'+id+'/delete');
    }
}
