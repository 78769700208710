import endpoint from "@/api/endpoint";

export default {
    getEvents() {
        return window.axios.get(endpoint + '/api/events');
    },

    getLastEvents() {
        return window.axios.get(endpoint + '/api/events/last');
    },

    getEvent(id) {
        return window.axios.get(endpoint + '/api/event/' + id);
    },

    getAuthEvent(id) {
        return window.axios.get(endpoint + '/api/auth_event/' + id);
    },

    getEventParticipants(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/participants');
    },

    getEventParticipantsShot(id, limit = 10) {
        return window.axios.get(endpoint + '/api/event/' + id + '/participants/shot', {params: {limit}});
    },

    getEventAdmins(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/admins');
    },

    getEventExperts(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/experts');
    },

    getEventPoints(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/user_points');
    },


    getAvailableTeams(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/available_teams');
    },

    getAvailableProjects(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/available_projects');
    },

    getAvailableParticipants(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/available_participants');
    },

    getInvitations(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/invitations');
    },

    getEventTeamPoints(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/team_points');
    },

    getAdminEventTeamPoints(id) {
        return window.axios.get(endpoint + '/api/event/' + id + '/getAdminTeamPointsSet');
    },

    updateAdminEventTeamPoints(id, set) {
        return window.axios.post(endpoint + '/api/event/' + id + '/updateTeamPointsBySet', {set});
    },

    updateUserEventPoints(participant_id, admin_id, rubric_id, points_earned) {
        return window.axios.get(endpoint + '/api/user_points/update', {
            params: {
                participant_id,
                admin_id,
                rubric_id,
                points_earned
            }
        });
    },

    updateTeamEventPoints(team_id, admin_id, rubric_id, points_earned) {
        return window.axios.get(endpoint+'/api/team_points/update', {params: {team_id, admin_id, rubric_id, points_earned}});
    },

    userEventCheck(user_id, event_id){
        return window.axios.get(endpoint+'/api/event/'+event_id+'/participants/'+user_id+'/check');
    },

    rateEvent(data){
        return window.axios.post(endpoint+'/api/event/'+data.event_id+'/participants/'+data.user_id+'/rateEvent', data);
    },

    changeParticipantRole(data){
        return window.axios.post(endpoint+'/api/event/'+data.event_id+'/participants/'+data.user_id+'/changeParticipantRole', data);
    },

    getUserStatus(event_id){
        return window.axios.get(endpoint+'/api/event/'+event_id+'/status');
    },

    getEventTypes(){
        return window.axios.get(endpoint+'/api/event_types');
    },

    store(data){
        return window.axios.post(endpoint+'/api/events/store', data);
    },

    update(data) {
        return window.axios.post(endpoint + '/api/events/' + data.id + '/update', data);
    },

    link(data) {
        return window.axios.get(endpoint + '/api/event/' + data.id + '/link');
    },

    stats(data) {
        return window.axios.get(endpoint + '/api/events/' + data.id + '/stats');
    },

    records(data) {
        return window.axios.get(endpoint + '/recs/' + data.id);
    }
}
