import departments from "@/api/departments";
import student_groups from "@/api/student_groups";
import institutes from "@/api/institutes.js";
import project_pools from "@/api/project_pools.js";

export default {
    state: {
        departments: [],
        studentGroups: [],
        task_statuses: [],
        institutes: [],
        project_pools: []
    },
    mutations: {
        setDepartments(state, departments) {
            state.departments = departments
        },
        setStudentGroups(state, student_groups) {
            state.studentGroups = student_groups;
        },

        addStudentGroup(state, student_group) {
            state.studentGroups.push(student_group);
        },

        addInstitute(state, institute) {
            state.institutes = [...state.institutes, institute]
        },

        updateInstitute(state, institute) {
            state.institutes = state.institutes.map((i) => i.id === institute.id ? institute : i);
        },

        addDepartment(state, department) {
            state.departments = [...state.departments, department]
        },

        updateDepartment(state, department) {
            state.departments = state.departments.map((i) => i.id === department.id ? department : i);
        },
        setInstitutes(state, institutes) {
            // eslint-disable-next-line no-console
            console.log('!', institutes);
            state.institutes = institutes;
        },
        setProjectPools(state, pp) {
            state.project_pools = pp;
        },

        updateProjectPool(state, pp) {
            state.project_pools = state.project_pools.map((p => p.id === pp.id ? pp : p))
        }

    },
    getters: {
        getStudentsDepartments(state) {
            return state.departments.filter(department => {
                return department.for_students === 1;
            })
        },

        getDepartmentByID(state) {
            return (id) => {
                return state.departments.filter(department => {
                    return parseInt(department.id) === parseInt(id);
                })[0];
            }
        },
        getDepartmentsByInstituteID(state) {
            return (id) => {
                return state.departments.filter(department => {
                    return parseInt(department.institute_id) === parseInt(id);
                });
            }
        }
    },
    actions: {
        getDepartments({commit}) {
            return departments.all().then((response) => {
                commit('setDepartments', response.data);
            })
        },

        getProjectPools({commit}) {
            return project_pools.all().then((response) => {
                commit('setProjectPools', response.data);
            })
        },

        getStudentGroups({commit}) {
            return student_groups.all().then((response) => {
                commit('setStudentGroups', response.data);
            })
        },

        deleteStudentGroup({commit}, {id}) {
            return student_groups.delete(id).then((response) => {
                commit('setStudentGroups', response.data);
            })
        },

        getInstitutes({commit}) {
            return institutes.all().then((response) => {
                commit('setInstitutes', response.data);
            })
        },

        createInstitute({commit}, {name}) {
            return institutes.create(name).then((response) => {
                commit('addInstitute', response.data);
            })
        },

        updateInstitute({commit}, {id, name}) {
            return institutes.update(id, name).then((response) => {
                commit('updateInstitute', response.data);
            })
        },

        deleteInstitute({commit}, {id}) {
            return institutes.delete(id).then((response) => {
                commit('setInstitutes', response.data);
            })
        },

        saveProjectPool({commit}, data) {
            return project_pools.update(data).then((response) => {
                commit('updateProjectPool', response.data);
            })
        },

        removeGroupFromPool({commit}, data) {
            return project_pools.removeGroupFromPool(data).then((response) => {
                commit('updateProjectPool', response.data);
            })
        },

        createDepartment({commit}, {name, institute_id}) {
            return departments.create(name, institute_id).then((response) => {
                commit('addDepartment', response.data);
            })
        },

        updateDepartment({commit}, {name, id}) {
            return departments.update(name, id).then((response) => {
                commit('updateDepartment', response.data);
            })
        },

        deleteDepartment({commit}, {id}) {
            return departments.delete(id).then((response) => {
                commit('setDepartments', response.data);
            })
        },


        createStudentGroup({commit}, data) {
            return new Promise((resolve, reject) => {
                student_groups.create(data).then((response) => {
                    resolve(response.data);
                    commit('addStudentGroup', response.data);
                }).catch(error => {
                    reject(error);
                })
            });
        }
    }
}


