<template>
  <div ref="container" >
    <div class="d-flex align-end" style="overflow-y: scroll" >
      <div v-for="file in files" :key="file.id">
        <v-img style="border-radius: 20px; overflow: hidden" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
               class="mr-2" height="50" :width="`100px`" cover
               v-if="file.isImage" :src="file.link">
          <v-btn dark style="position:absolute; right: 0; top: 0" icon>
            <v-icon @click="() => file.remove()">
              mdi-close
            </v-icon>
          </v-btn>
        </v-img>
        <span v-else>
        <v-chip @click:close="() => file.remove()" :close="!file.is_local">
           <v-progress-circular v-if="file.is_local"
                                :width="1"
                                color="primary"
                                :value="file.progress"
                                :size="20"
                                class="mr-2"
           ></v-progress-circular>
              {{ file.filename }}
        </v-chip>

      </span>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "FileHorizontalViewer",
  props: ['files'],
  methods: {
    min(){
      return Math.min;
    }
  }
}
</script>

<style scoped>

</style>
