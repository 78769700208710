import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import axios from 'axios';
import events from "@/store/modules/events";
import dictionaries from "@/store/modules/dictionaries";
import timetables from "@/store/modules/timetables";
import discreator from "@/store/modules/discreator";
import groupcreator from "@/store/modules/groupcreator";
import lessonmanager from "@/store/modules/lessonmanager";
import projects from "@/store/modules/projects";
import chats from "@/store/modules/chats.js";
import Echo from "laravel-echo"
import tasks from "@/store/modules/tasks.js";
import university from "@/store/modules/university.js";
import JSConfetti from 'js-confetti'
import feed from "@/store/modules/feed";
import Tracker from '@openreplay/tracker';


const startTrack = () => {
    if (!window.tracker) {
        window.tracker = new Tracker({
            projectKey: "ZFevFgnIF8DukahL7F0K",
        });
        window.tracker.start();
    }
}

window.document.addEventListener('scroll', () => {
    // eslint-disable-next-line no-console
    console.log('scroll')
    startTrack();
}, {once: true})
window.document.addEventListener('click', () => {
    startTrack();
    // eslint-disable-next-line no-console
    console.log('click')
}, {once: true})

window.document.addEventListener('mousemove', () => {
    startTrack();
    // eslint-disable-next-line no-console
    console.log('mousemove')
}, {once: true})


if (process.env.NODE_ENV === 'production') {
    window.io = require('socket.io-client');

    window.Echo = new Echo({
        broadcaster: 'socket.io',
        namespace: false,
        authEndpoint: process.env.NODE_ENV === 'development' ? 'http://localhost:8000/broadcasting/auth' : 'https://api.project-novsu.ru/broadcasting/auth',// 'http://localhost:8000/broadcasting/auth',
        host: process.env.NODE_ENV === 'development' ? 'localhost:6001' : 'api.project-novsu.ru:6001',// 'http://localhost:8000/broadcasting/auth',
        auth: {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                Accept: 'application/json',
            },
        },
    });

}


window.axios = axios;

window.jsConfetti = new JSConfetti()

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        user,
        events,
        dictionaries,
        timetables,
        discreator,
        groupcreator,
        lessonmanager,
        projects,
        chats,
        tasks,
        university,
        feed
    },
    strict: debug
})
