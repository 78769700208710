<template>
  <v-container>
    <h1>Хостинг</h1>
    <p>Вы можете воспользоваться вычислительными ресурсами для хостинга своего IT - проекта.</p>
    <v-btn class="my-2" @click="showCreateDialog = true" outlined color="primary" x-small>Создать Mysql DB</v-btn>
    <v-badge overlap color="primary" content="Скоро">
      <v-btn class="ml-2 my-2" disabled outlined color="primary" x-small>Захостить Docker контейнер</v-btn>
    </v-badge>

    <Empty class="mx-auto my-3" title="Вычислительных ресурсов нет," description="но вы можете создать "
           v-if="!hosts.length"/>
    <v-dialog max-width="500" v-model="showCreateDialog">
      <v-card>
        <v-card-title>
          Создать MySQL DB
        </v-card-title>
        <v-card-text>
          <v-text-field :rules="[(value) => /[a-z0-9]{3,10}/g.test(value) || 'от 3 до 10 латинских букв и цифр в нижнем регистре без пробелов']" v-model="createDb.db_name" placeholder="Имя базы данных"/>
          <v-btn :disabled="!/[a-z0-9]{3,10}/g.test(createDb.db_name)" @click="create" outlined color="primary" rounded>Cоздать</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CardUI class="mt-2" v-for="host in hosts" :key="host.id">
      <template v-slot:header>
        #{{ host.id }} Mysql DB
        <v-chip v-if="host.status === 1" class="ml-4" color="green" dark>Готова к использованию</v-chip>
        <v-chip v-if="!host.status" class="ml-4" color="warning" dark>Создается</v-chip>
        <v-chip v-if="host.status === -1" class="ml-4" color="error" dark>Удаляется</v-chip>
      </template>
      <template v-slot:body>
        <div class="pa-2">
          PhpMyAdmin: <a target="_blank" href="http://92.246.214.15:5000/">http://92.246.214.15:5000/</a>
        </div>
        <div class="pa-2">
          IP: <a :href="host.db_host">{{ host.db_host }}</a>
          <v-btn icon small @click="share(host.db_host)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </div>

        <div class="pa-2">
          Логин: {{ host.login }}
          <v-btn icon small @click="share(host.login)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </div>
        <div class="pa-2">
          Пароль: {{ host.password }}
          <v-btn icon small @click="share(host.password)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>

        </div>
        <div class="pa-2">
          Имя базы данных: {{ host.db_name }}
          <v-btn icon small @click="share(host.db_name)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </div>
        <v-btn v-if="host.status > 0" @click="destroy(host.id)" outlined color="error" rounded small>Удалить БД</v-btn>
      </template>
    </CardUI>
  </v-container>
</template>

<script>
import hosting from "@/api/hosting";
import CardUI from "@/components/UI/CardUI";
import analytics from "@/api/analytics";
import Empty from "@/components/Common/Empty";

export default {
  name: "HostingPageComponent",
  components: {CardUI, Empty},
  data() {
    return {
      hosts: [],
      createDb: {
        db_name: ''
      },
      showCreateDialog: false
    }
  },
  methods: {
    share(url) {
      navigator.clipboard.writeText(url)
    },
    destroy(id) {
      if (confirm('Вы уверены, что хотите безвозвратно удалить БД?')) {
        hosting.destroy(id).then(() => {
          this.load();
          analytics.destroyDB(this.$store.state.user.currentUser.id);
        })
      }
    },
    load(){
      hosting.all().then((r) => {
        this.hosts = r.data;
      })
    },
    create(){
      hosting.create(this.createDb.db_name).then(() => {
        this.createDb.db_name = '';
        this.showCreateDialog = false;
        analytics.createDB(this.$store.state.user.currentUser.id);
        this.load();
      })
    }
  },
  mounted() {
    if (!this.$store.state.user.currentUser.id) return this.$router.push('/auth')
    this.load();
    analytics.openHostingPage(this.$store.state.user.currentUser.id);
    setInterval(() => {
      this.load()
    }, 5000);
  }
}
</script>

<style scoped>

</style>
