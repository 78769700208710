<template>
  <div>
    <CardUI :loading="loading">
      <template v-slot:body>
        <v-card-text>
          <v-alert v-for="(e,i) in errors" :key="i" type="error">{{ e[0] }}</v-alert>
          <h4 class="my-4">Название мероприятия</h4>
          <InputUI v-model="event.name" :errors="errors.name" outlined/>
          <h4 class="my-4">Описание мероприятия</h4>
          <TextAreaUI v-model="event.description" :errors="errors.description" placeholder="Описание"/>

          <h4 class="my-4">Как вы планируете провести мероприятие?</h4>
          <SelectorUI v-model="event.is_online" :default-value="true" :items="[
                          {name: 'Онлайн', value: true},
                          {name: 'Оффлайн', value: false},
                      ]"/>


          <InputUI v-if="event.is_online" class="mt-4" v-model="event.meet_url" :errors="errors.phone"
                   placeholder="Ссылка для подключения"/>


          <v-switch v-if="!event.is_online" v-model="inner"
                    label="Мероприятие проводится внутри университета"/>


          <InputUI v-if="!inner && !event.is_online" v-model="event.address" :errors="errors.address"
                   placeholder="Адрес"/>
          <SelectUI v-if="inner" v-model="event.place_id" :clearable="true"
                    :errors="errors.place_id"
                    :items="$store.state.timetables.places"
                    item-text="name"
                    item-value="id"
                    no-data-text="Такой аудитории у нас нет"
                    placeholder="Аудитория проведения"
          >

          </SelectUI>
          <h4 class="my-4">Период регистрации</h4>
          <DatePickerUIDecorator>
            <DatePicker format="DD.MM" :disabled-date="notBeforeToday"
                        :range=true
                        value-type="YYYY-MM-DD HH:mm:ss"
                        :show-second="false"
                        :value="[event.check_start_at, event.check_end_at]" input-class="date-input"
                        placeholder="Период регистрации"
                        type="date"
                        @input="event.check_start_at = $event[0]; event.check_end_at = $event[1];"></DatePicker>
          </DatePickerUIDecorator>

          <h4 class="my-4">Период проведения</h4>
          <DatePickerUIDecorator>
            <DatePicker value-type="YYYY-MM-DD HH:mm:ss" format="DD.MM HH:mm" :disabled-date="notBeforeTodayAndCheck"
                        :disabled-time="notBeforeTodayAndCheck" :range=true
                        :show-second="false" :value="[event.start_at, event.end_at]" placeholder="Период проведения"
                        type="datetime" input-class="date-input"
                        @input="event.start_at = $event[0]; event.end_at  = $event[1];"></DatePicker>
          </DatePickerUIDecorator>
          <SelectUI class="mt-4" v-model="event.event_type_id" :errors="errors.event_type_id"
                    :items="$store.state.events.event_types" item-text="name" item-value="id"
                    placeholder="Тип мероприятия"/>

          <InputUI class="mt-4" v-model="event.phone" v-mask="'+7##########'" :errors="errors.phone"
                   placeholder="Телефон для справки"/>
          <v-switch v-model="event.teams_allowed" :error-messages="errors.teams_allowed"
                    label="Командное участие"/>
          <h4>Выберите постер</h4>
          <FileUpload v-model="header_bg" @input="event.header_url = $event[0].link" :only-types="/jpg|png|gif|jpeg/"
                      :multiple="false" class="mt-3 mb-3"/>
        </v-card-text>
        <v-card-actions>
          <ButtonUI :loading="loading" color="blue darken-2" outlined @click="save">Сохранить мероприятие</ButtonUI>
        </v-card-actions>
      </template>
    </CardUI>
  </div>
</template>

<script>
import ButtonUI from "@/components/UI/ButtonUI";
import FileUpload from "@/components/Common/FileUpload";
import SelectUI from "@/components/UI/SelectUI";
import CardUI from "@/components/UI/CardUI";
import InputUI from "@/components/UI/InputUI";
import TextAreaUI from "@/components/UI/TextAreaUI";
import SelectorUI from "@/components/UI/SelectorUI";
import DatePicker from "vue2-datepicker";
import DatePickerUIDecorator from "@/components/UI/DatePickerUIDecorator";
import {mask} from 'vue-the-mask'

export default {
  name: "EventEditWidget",
  directives: {
    mask,
  },
  components: {
    ButtonUI,
    FileUpload,
    SelectUI,
    CardUI, InputUI, TextAreaUI, SelectorUI, DatePicker, DatePickerUIDecorator
  },
  data() {
    return {
      header_bg: [],
      loading: true,
      errors: [],
      inner: false,
      event: {
        place_id: null,
        name: null,
        address: null,
        meet_url: null,
        phone: null,
        description: null,
        check_start_at: null,
        check_end_at: null,
        teams_allowed: false,
        start_at: null,
        end_at: null,
        event_type_id: null,
        user_role_id: null,
        is_online: false
      }
    }
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    notBeforeTodayAndCheck(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0)) || date < new Date(this.event.check_end_at);
    },
    save() {
      this.loading = true;
      this.$store.dispatch('updateEvent', {
        ...this.event,
        check_start_at: this.$moment(this.event.check_start_at).utc().format("YYYY-MM-DD HH:mm:ss"),
        check_end_at: this.$moment(this.event.check_end_at).utc().format("YYYY-MM-DD HH:mm:ss"),
        end_at: this.$moment(this.event.end_at).utc().format("YYYY-MM-DD HH:mm:ss"),
        start_at: this.$moment(this.event.start_at).utc().format("YYYY-MM-DD HH:mm:ss"),
      }).then(() => {
        this.loading = false;
      }).catch((e) => {
        this.errors = e.response.data.errors
      })
    }
  },
  mounted() {
    this.event = {...this.$store.state.events.currentEvent};
    this.event.start_at = this.$moment.utc(this.event.start_at).local().format('YYYY-MM-DD HH:mm:ss')
    this.event.end_at = this.$moment.utc(this.event.end_at).local().format('YYYY-MM-DD HH:mm:ss')
    this.event.check_end_at = this.$moment.utc(this.event.check_end_at).local().format('YYYY-MM-DD HH:mm:ss')
    this.event.check_start_at = this.$moment.utc(this.event.check_start_at).local().format('YYYY-MM-DD HH:mm:ss')
    Promise.all([
      this.$store.dispatch('getEventTypes'),
      this.$store.dispatch('getPlaces'),
      this.$store.dispatch('getUserRoles')
    ]).then(() => {
      this.loading = false;
    })
  }
}
</script>

<style scoped>

</style>
