<template>
  <div class="message"
       :class="{'message_my': $store.state.user.currentUser.id === message.owner.id, 'message_other': $store.state.user.currentUser.id !== message.owner.id}">
    <div class="message__avatar">
      <v-avatar size="40">
        <v-img :src="message.owner.avatar"/>
      </v-avatar>
    </div>
    <div class="message__text">

      <a :href="'#/profile/'+message.owner.id" class="message__owner-name"> {{ message.owner.name }} </a>
      <div style="padding: 10px; border-radius: 10px; overflow: hidden" :key="attachment.id"
           v-for="attachment in message.attachments">
        <v-img style="border-radius: 10px; overflow: hidden"
               max-width="400" :src="attachment.link"
               v-if="attachment.type === 'png' || attachment.type === 'jpg' || attachment.type === 'gif' || attachment.type === 'jpeg'"></v-img>
        <div v-else-if="attachment.type === 'mp4'">
          <video autoplay controls :src="attachment.link" style="max-width: 300px;"></video>
        </div>
        <a target="_blank" :href="attachment.link">{{ attachment.filename }}</a>
      </div>
      <span v-html="urlify(message.text)" v-if="message.text" style="white-space: pre-wrap">
      </span>


      <v-progress-circular v-if="message.id < 0" class="float-right" width="1" color="primary" indeterminate :size="14"/>
      <div class="message__date">
        <template v-if="message.readers.length > 0">
          {{ message.readers.length }}
          <v-icon size="14">mdi-check-all</v-icon>
        </template>
        {{ $moment.utc(message.created_at).local().format("HH:mm") }}
      </div>

    </div>

  </div>

</template>

<script>
import chats from "@/api/chats.js";
import analytics from "@/api/analytics.js";

export default {
  name: "SmartMessage",
  props: ['message'],
  mounted() {
    if (this.message.readers.map(r => r.id).indexOf(this.$store.state.user.currentUser.id) === -1) {
      analytics.event({event: 'CHAT_MESSAGE_WAS_READ', details: this.message});
      chats.markAsRead({id: this.message.id})
    }
  },
  methods: {
    urlify(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
      })
    },
  }
}
</script>

<style scoped>
.message {
  width: 80%;
  display: flex;
  align-items: flex-end;
}

.message__text {
  word-break: break-word;
  background: #eaeaea;
  padding: 10px 10px 15px;
  flex-shrink: 2;
  width: 100%;
  white-space: pre-wrap;
  position: relative;
  font-size: 14px;
}

.message_my {
  float: right;
  justify-content: flex-end;
}

.message__date {
  position: absolute;
  font-size: 10px;
  bottom: 0.2em;
  right: 0.5em;
}

.message_my .message__text {
  order: -1;
  justify-content: flex-end;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 1em;
}

.message_other .message__text {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-left: 1em;
}

.message__owner-name {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #02405d;
  text-decoration: none;
}
</style>
