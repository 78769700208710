<template>
  <div>
    <input multiple @input="onUpload" ref="files" type="file" class="d-none"/>
    <slot v-bind:attachFiles="attachFiles" name="activator"/>
  </div>
</template>

<script>
import files from "@/api/files";

export default {
  name: "FileUploadV2",
  props: ['value'],
  data() {
    return {
      files: []
    }
  },
  watch: {
     value(){
       this.files = this.value;
     }
  },
  mounted() {
    if(this.value.length){
      this.files = this.value;
    }
  },
  methods: {
    attachFiles() {
      this.$refs.files.click();
    },
    onUpload(event) {
      Array.from(event.target.files).forEach((file) => {
        this.uploadFile(file);
      });
      this.$refs.files.value = '';
    },

    removeFile(file){
      this.files = this.files.filter(f => f.id !== file.id)
      this.$emit('input', this.files);
    },

    fileProgress(vid) {
      return (event) => {
        let progress = Math.round((event.loaded * 100) / event.total);
        this.files = this.files.slice().map((f) => f.id === vid ? {...f, progress} : f);
        this.$emit('input', this.files);
      };
    },

    uploadFile(file) {
      const vid = Math.random().toString(16).slice(2);
      this.files.push({
        id: vid,
        is_local: true,
        file: file,
        filename: file.name,
        progress: 0
      });
      this.$emit('input', this.files);

      let bodyFormData = new FormData();
      bodyFormData.append('file', file);
      files.store(bodyFormData, this.fileProgress(vid)).then((r) => {
        this.files = this.files.slice().map((f) => f.id === vid ? {remove: () => {this.removeFile(r.data)}, ...r.data} : f);
        this.$emit('input', this.files.slice().filter((f) => f.is_local === undefined))
      }).catch(e => {
        if (e.response.status === 413) {
          this.files = this.files.slice().map((f) => f.id === vid ? {
            ...f,
            error: 'Превышен максимальный размер файла'
          } : f);
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
