<template>
  <div class="input-items">
    <div @click="$emit('select', item)" v-for="(item, index) in items" :key="index" class="input-items__item">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "InputSelectable",
  props: {
    items: {}
  }
}
</script>

<style scoped>

.input-items {
  width: 100%;
  border-radius: 10px;
  background-color: #fefefe;
  top: 100%;
  z-index: 1000;
  position: absolute;
  max-height: 350px;
  overflow: scroll;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
}

.input-items__item {
  padding: 10px;
}

.input-items__item:hover {
  cursor: pointer;
  background: #eaeeef;
}

.input-select-wrapper {
  position: relative;
}


.input-items::-webkit-scrollbar {
  width: 2px; /* width of the entire scrollbar */
  height: 0;
}

.input-items::-webkit-scrollbar-track {
  background: #999999; /* color of the tracking area */
}

.input-items::-webkit-scrollbar-thumb {
  background-color: #eaeeef;; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 1px solid #dedede; /* creates padding around scroll thumb */
}
</style>
