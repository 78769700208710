import chats from "@/api/chats.js";
import inboxes from "@/api/inboxes.js";

export default {
    state: {
        chats: [],
        users: [],
        currentChatMessages: [],
        showChat: true,
        unreadCount: 0
    },
    mutations: {
        setChats(state, chats) {
            state.chats = chats;
        },
        hideChat(state) {
            state.showChat = false;
        },
        setUnreadCount(state, count) {
            state.unreadCount = count;
        },
        updateChat(state, chat) {
            state.chats = state.chats.map((ch) => ch.id == chat.id?chat:ch);
        },
        setUsers(state, users) {
            state.users = users;
        },
        addChat(state, chat) {
            state.chats = [...state.chats, chat]
        },
        setMessages(state, messages) {
            state.currentChatMessages = messages;
        },
        addMessage(state, message) {
            state.currentChatMessages = [...state.currentChatMessages, message];
        }
    },
    getters: {},
    actions: {
        getChats({commit}) {
            return chats.my().then((response) => {
                return commit('setChats', response.data);
            })
        },

        getUnreadCount({commit}) {
            return inboxes.count().then((response) => {
                commit('setUnreadCount', response.data.count);
            })
        },

        hideChat({commit}) {
            commit('hideChat')
        },
        getUsers({commit}) {
            return chats.users().then((response) => {
                commit('setUsers', response.data);
            })
        },
        createChat({commit}, data) {
            return chats.create(data).then((response) => {
                commit('addChat', response.data);
            })
        },
        getCurrentChatMessages({commit}, data) {
            return chats.messages(data).then((response) => {
                commit('setMessages', response.data);
            })
        },
        sendMessage(_, data) {
            return chats.sendMessage(data)
        }
    }
}


